////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(166, 152, 134);
}
.bg-secondaryColor {
  background-color: rgb(166, 152, 134);
}
.bg-thirdColor {
  background-color: rgb(166, 152, 134);
}
.bg-fourthColor {
  background-color: rgb(166, 152, 134);
}
.bg-fifthColor {
  background-color: rgb(255, 255, 255);
}
.bg-sixthColor {
  background-color: rgb(166, 152, 134);
}
.bg-seventhColor {
  background-color: rgb(121, 107, 89);
}
